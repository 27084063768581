<template>
	<div class="RpaPlan"></div>
</template>

<script>
export default {
	components: {},
	name: "RpaPlan",
	data() {
		return {};
	},
};
</script>
<style lang="less" scoped></style>
